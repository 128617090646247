import React, {Fragment, forwardRef} from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import {FieldArray} from 'react-final-form-arrays'

import AssignDocumentType from 'components/AssignDocumentType'
import FlatList from 'components/FlatList'
import type {DocumentUploadType} from 'types/orderTypes'
import type {OrderFormDocument, ProductGroupType, ProductType} from 'types/productTypes'
import UploadButton from 'components/UploadButton'
import {useField, useForm} from 'react-final-form'
import {validateUploadedDocuments} from 'services/formValidatorServices'
import {useSelector} from 'react-redux'
import {isDocumentUploadModalOpenSelector, orderByOpsOrderIdSelector} from 'selectors'
import {getDocumentTypeOptions} from 'services/documentsServices'

interface UploadDocumentsProps {
  className?: string
  fieldName: string
  requiredDocuments?: DocumentUploadType[]
  isUploadDocumentRequired?: boolean
  productGroup: ProductGroupType
  productType?: ProductType
}

const UploadDocuments = forwardRef<HTMLDivElement, UploadDocumentsProps>(
  (
    {
      className,
      fieldName,
      requiredDocuments = [],
      isUploadDocumentRequired = false,
      productGroup,
      productType
    }: UploadDocumentsProps,
    ref
  ) => {
    const {
      mutators: {push}
    } = useForm()
    const isDocumentUploadModalOpen = useSelector(isDocumentUploadModalOpenSelector)
    const order = useSelector(orderByOpsOrderIdSelector)
    const options = getDocumentTypeOptions(
      productGroup,
      order.status?.orderStatus === 'COMPLETED' ? order.productType : productType
    )
    // we are pulling meta form the useField hook since meta.error seems to be incorrect with the version
    // of RFF being used https://github.com/final-form/react-final-form/issues/980
    const {meta} = useField(fieldName)

    return (
      <StyledUploadDocuments className={classnames(className)} ref={ref}>
        <FieldArray<OrderFormDocument>
          key={requiredDocuments.join()}
          name={fieldName}
          validate={value =>
            validateUploadedDocuments(
              requiredDocuments,
              value,
              isUploadDocumentRequired,
              isDocumentUploadModalOpen
            )
          }
        >
          {({fields}) => {
            return (
              <Fragment>
                <UploadButton
                  className='select-files-button'
                  onSelectFiles={(files: FileList) => {
                    Object.values(files).forEach(file => {
                      push(fieldName, {
                        file
                      })
                    })
                  }}
                  productGroup={productGroup}
                />
                {/* We only care about errors return by validateRequiredDocuments. Unfortunately FieldArray will set meta.error to an
                    array containing the errors from the fields contained within the FieldArray if validateRequiredDocuments doesn't return an error.
                    Because of this we need to check is meta.error is a string since we know that means validateRequiredDocuments has returned an error.
                  */}
                {meta.submitFailed && meta.error && typeof meta.error === 'string' && (
                  <p className='error'>{meta.error}</p>
                )}
                <FlatList
                  className='upload-documents-flat-list'
                  items={fields.map((name, index) => {
                    const document = fields.value[index]

                    return {
                      className: 'document-item',
                      key: name,
                      content: (
                        <AssignDocumentType
                          key={name}
                          fieldName={name}
                          document={document}
                          options={options}
                          index={index}
                          productGroup={productGroup}
                        />
                      )
                    }
                  })}
                  withDividers
                />
              </Fragment>
            )
          }}
        </FieldArray>
      </StyledUploadDocuments>
    )
  }
)

const StyledUploadDocuments = styled.div`
  width: 100%;

  .file-upload-icon {
    width: 16px;
    color: ${({theme}) => theme.colors.grayscale.black};
    font-size: 1rem;
    margin-right: 4px;
  }

  .document-item {
    padding: 16px 0;

    &:first-child {
      padding-top: 24px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .error {
    color: ${({theme}) => theme.colors.ruby.base};
    font-size: 0.75rem;
    margin: 8px 0 0 0;
  }
`

export default UploadDocuments
