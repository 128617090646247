import React from 'react'
import MUITableContainer from '@material-ui/core/TableContainer'
import MUITable from '@material-ui/core/Table'
import MUITableHead from '@material-ui/core/TableHead'
import MUITableRow from '@material-ui/core/TableRow'
import MUITableCell from '@material-ui/core/TableCell'
import MUITableBody from '@material-ui/core/TableBody'
import {getSqaId} from 'services/testingServices'
import classnames from 'classnames'
import styled from 'styled-components'
import {useParams} from 'react-router-dom'
import {MESSAGE_CENTER_TABLE_HEADER} from 'constants/messageCenterConstants'
import useOrderMessages from 'hooks/useOrderMessages'
import {getFormattedDate} from 'services/dateServices'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import DOMPurify from 'dompurify'

export default function MessageCenterTable() {
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const {data: messages} = useOrderMessages(opsOrderId)

  return (
    <MessageCenterTable.Styled>
      <MUITable stickyHeader {...getSqaId('-table', 'message-center')}>
        <MUITableHead {...getSqaId('-table-head', 'message-center')}>
          <MUITableRow>
            {MESSAGE_CENTER_TABLE_HEADER.map(
              (
                {
                  id,
                  label,
                  // @ts-ignore there is no sortable in tableHeaders
                  sortable = false,
                  style
                },
                index
              ) => {
                return (
                  <MUITableCell
                    className={classnames('header-sticky', {
                      sortable
                    })}
                    key={id}
                    style={style}
                    data-sqa-id={`header-c${index}`}
                  >
                    {label}
                  </MUITableCell>
                )
              }
            )}
          </MUITableRow>
        </MUITableHead>
        <MUITableBody {...getSqaId('table-body', 'message-center')}>
          {messages?.map((message, rowIndex) => (
            <MUITableRow
              key={`${message.messageId}-row-${rowIndex}`}
              data-sqa-id={`row-${rowIndex}`}
            >
              {MESSAGE_CENTER_TABLE_HEADER.map((headerCell, colIndex) => {
                const sqaId = `cell-r${rowIndex}c${colIndex}`
                return (
                  <MUITableCell
                    key={`${message.messageId}-${headerCell.id}`}
                    {...getSqaId(`row-${headerCell.id}-cell`, 'message-center')}
                    data-sqa-id={sqaId}
                    className={headerCell.id}
                  >
                    {headerCell.id === 'created' ? (
                      <div className='date-time-wrapper'>
                        <span className='date-time' data-sqa-id={`data-time-${sqaId}`}>
                          {getFormattedDate(message[headerCell.id])}
                        </span>
                        {message.isUrgent && (
                          <NotificationImportantIcon
                            className='icon-notification'
                            data-sqa-id={`icon-${sqaId}`}
                          />
                        )}
                      </div>
                    ) : headerCell.id === 'message' ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            message[headerCell.id].replace('\r\r', '<br/>'),
                            {
                              USE_PROFILES: {html: true}
                            }
                          )
                        }}
                      />
                    ) : (
                      message[headerCell.id]
                    )}
                  </MUITableCell>
                )
              })}
            </MUITableRow>
          ))}
        </MUITableBody>
      </MUITable>
    </MessageCenterTable.Styled>
  )
}

MessageCenterTable.Styled = styled(MUITableContainer)`
  thead {
    .portal-MuiTableCell-stickyHeader {
      background-color: ${({theme}) => theme.colors.grayscale.white};
    }
    th {
      border-bottom: 1px solid ${({theme}) => theme.colors.stone.darker};
    }
  }
  tbody {
    .message {
      white-space: normal;
    }
    .portal-MuiTableRow-root {
      vertical-align: top;
    }
    .date-time-wrapper {
      display: flex;
      align-items: center;
      .date-time {
        width: 151px;
      }
      .icon-notification {
        height: 22px;
        width: 22px;
        color: ${({theme}) => theme.colors.ruby.base};
      }
    }
  }
`
