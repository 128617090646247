// Components, services, etc
import {
  getAccessToken,
  getBasicToken,
  oauthApi,
  updateAuthTokens,
  updateBasicToken
} from 'services/apis'
import store from 'services/store'
import {openModal} from 'actions/modalActions'
import {LOG_IN_SIGN_UP_MODAL} from 'constants/modalsConstants'
import {RESET_APP} from 'constants/actionTypes'
import {casRefreshToken} from 'services/casServices'

// 3rd-party
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import {RetypeFunc} from './FetchApi'
import type {GetAccessTokenResponse} from 'types/authenticationTypes'
import {openNotification} from './notificationServices'
import {SESSION_EXPIRED_MESSAGE} from 'constants/notificationMessageConstants'

const EXPIRATION_BUFFER_IN_SECONDS = 30

export const isAccessTokenExpiredOrAboutToExpire = () => {
  const token = getAccessToken()
  return isTokenExpiredOrAboutToExpire(token)
}

export const isBasicTokenMissingExpiredOrAboutToExpire = () => {
  const token = getBasicToken()
  return !token || isTokenExpiredOrAboutToExpire(token)
}

export const handleExpiredAccessToken = (retry: RetypeFunc) => {
  const {dispatch} = store
  return casRefreshToken()
    .then(res => {
      if (res.accessToken) {
        // update the tokens (this will update both the access token and basic auth token)
        updateAuthTokens(res.accessToken)

        // continue with the request using retry so that the new accessToken
        // that was just fetched and set is used
        return retry()
      }
    })
    .catch(err => {
      const {response} = err
      const isLoginSignUpModalOpen = store.getState().modals.length > 0

      // if the refresh token itself has expired or has been revoked (like on sign out)
      // then we need to force the user to log back in before we can continue
      if (response.status === 401) {
        const promise = new Promise((resolve, reject) => {
          const onSuccess = () => {
            return retry()
              .then(res => resolve(res))
              .catch(err => {
                return reject(err)
              })
          }
          const handleClose = (forceLogout?: boolean) => {
            if (forceLogout) {
              // clear the locally stored tokens (this will update both the access token and basic auth token)
              updateAuthTokens(undefined)

              // reset app state
              dispatch({
                type: RESET_APP
              })
            }
          }

          if (!isLoginSignUpModalOpen) {
            dispatch({
              type: RESET_APP
            })

            // clear the locally stored tokens (this will update both the access token and basic auth token)
            updateAuthTokens(undefined)
            openNotification({
              type: 'error',
              text: SESSION_EXPIRED_MESSAGE
            })

            dispatch(
              openModal({
                modalType: LOG_IN_SIGN_UP_MODAL,
                mode: 'logIn',
                onSuccess,
                handleClose,
                mixpanelEventData: {
                  initiatedFrom: 'Token Service - Expired Refresh Token'
                }
              })
            )
          }
        })

        return promise
      } else {
        throw err
      }
    })
}

export const handleExpiredBasicToken = (retry: () => void) => {
  return oauthApi
    .post<GetAccessTokenResponse>(
      'token',
      {
        grant_type: 'client_credentials',
        scope: 'property-aggregation-service/public'
      },
      {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    )
    .then(res => {
      updateBasicToken(res?.access_token)

      // continue with the request using retry so that the new accessToken
      // that was just fetched and set is used
      return retry()
    })
    .catch(err => {
      console.error(err)
      throw err
    })
}

function isTokenExpiredOrAboutToExpire(token?: string) {
  let tokenIsAboutToExpire = false
  if (token) {
    // get the expiration unix timestamp from our JWT token
    const {exp} = jwtDecode(token) as any
    const expirationMoment = moment(exp)
    const nowMoment = moment(Math.floor(new Date().valueOf() / 1000))
    const diff = expirationMoment.diff(nowMoment)
    tokenIsAboutToExpire = diff < EXPIRATION_BUFFER_IN_SECONDS
  }

  return tokenIsAboutToExpire
}
