import {getAccessToken} from 'services/apis'
import {
  handleExpiredAccessToken,
  handleExpiredBasicToken,
  isAccessTokenExpiredOrAboutToExpire,
  isBasicTokenMissingExpiredOrAboutToExpire
} from 'services/tokenServices'
import {WHITELISTED_ACCESS_TOKEN_ENDPOINTS} from 'services/authorization'
import {OutgoingHttpHeaders} from 'http2'
import FetchApiError from './FetchApiError'
import {FETCH_ERROR_MESSAGE_PREFIX} from 'constants/validationErrorMessagesConstants'
import {trackError} from './sentryServices'
import {getParametrizedURL} from './urlServices'

// Any URLs that are critical to the log in flow must be skipped when it comes
// to trying to automatically refresh the tokens.
const isAccessTokenWhitelistedUrl = (url: string) => {
  return isWhitelistedUrl(url, WHITELISTED_ACCESS_TOKEN_ENDPOINTS)
}

const isPropAggRequest = (url: string) => {
  return url.includes('property-aggregation')
}

const isWhitelistedUrl = (url: string, whitelistedUrls: string[]) => {
  let isWhitelisted = false
  for (let index = 0; index < whitelistedUrls.length; index++) {
    const urlBase = whitelistedUrls[index]
    isWhitelisted = url.includes(urlBase)
    if (isWhitelisted) {
      break
    }
  }

  return isWhitelisted
}

export type RetypeFunc<Response = any> = () => Promise<Response>
type Headers = NodeJS.Dict<string | number | (() => string | undefined) | null>

interface ApiProps {
  baseUrl: string
  errorHandler?: (err: FetchApiError) => void
  headers: Headers
}

export default class FetchApi {
  baseUrl: Required<ApiProps>['baseUrl']
  errorHandler: Required<ApiProps>['errorHandler']
  headers: Required<ApiProps>['headers']

  static headers = {
    Accept: 'application/json, text/javascript; q=0.9, */*; q=0.6',
    'Accept-Encoding': 'gzip, deflate, br',
    'Content-Type': 'application/json'
  }

  constructor(props: ApiProps) {
    const {baseUrl: propsBaseUrl, headers: propsHeaders, errorHandler: propsErrorHandler} = props
    this.baseUrl = propsBaseUrl
    this.headers = {
      ...FetchApi.headers,
      ...propsHeaders
    }
    this.errorHandler = (error: FetchApiError) => {
      if (typeof propsErrorHandler === 'function') {
        propsErrorHandler(error)
      } else {
        throw error
      }
    }

    if (typeof propsHeaders === 'object') {
      Object.keys(propsHeaders).forEach(key => {
        if (propsHeaders[key] === null) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete this.headers[key]
        } else {
          this.headers[key] = propsHeaders[key]
        }
      })
    }
  }

  request: <Response>(
    url: string,
    options: RequestInit,
    retry: RetypeFunc<Response>
  ) => Promise<Response> = (url, options, retry) => {
    const makeRequest = () => {
      const updatedURL = getParametrizedURL(url)
      return fetch(url, options).then(
        async response => {
          if (options.method === 'HEAD') {
            return response
          }
          if (response.ok) {
            if (response.status !== 204 && response.status !== 202) {
              let data
              try {
                switch (response.headers.get('content-type')) {
                  case 'image/jpeg':
                  case 'text/csv':
                    data = await response.text()
                    break
                  case 'application/pdf': {
                    const blob = await response.blob()
                    const filename =
                      response.headers.get('Content-Disposition')?.match(/filename="(.+)"/)?.[1] ??
                      null
                    data = {
                      blob,
                      filename
                    }
                    break
                  }
                  default:
                    data = await response.json()
                }
              } catch (err) {
                console.error('error trying to handle response', err)
              }

              return data
            }
          } else {
            // Do not move the FETCH_ERROR_MESSAGE_PREFIX from the beginning of the error message.
            // Our Sentry implementation looks for it (startWith()) in the event filtering logic.
            // See the `beforeSend` configuration in the Sentry initialization code.
            const apiError = new FetchApiError(
              `${FETCH_ERROR_MESSAGE_PREFIX}: ${options.method} ${updatedURL} ${response.status}${
                response.statusText ? ` (${response.statusText})` : ''
              }`
            )
            apiError.response = response

            if (response.headers.get('content-type') === 'application/json') {
              try {
                const json = await response.json()
                apiError.payload = json
              } catch (e) {
                console.error(e, 'could not parse response json')
              }
            }

            trackError(apiError, {
              options,
              parameterizedUrl: updatedURL,
              payload: apiError.payload,
              statusCode: response.status,
              url
            })
            this.errorHandler(apiError)
          }
        },
        err => {
          const callError = new FetchApiError('Call Error')
          callError.response = err

          trackError(callError, {
            options,
            parameterizedUrl: updatedURL,
            url
          })
          this.errorHandler(callError)
        }
      )
    }

    // if the user is logged in, we need to make sure their access token is still good
    if (getAccessToken()) {
      // if their access token is about to expire, we need to try to renew it
      if (isAccessTokenExpiredOrAboutToExpire() && !isAccessTokenWhitelistedUrl(url)) {
        // using `retry` instead of `request` will ensure the new token that
        // handleExpiredToken fetches and sets will be used instead of the
        // same accessToken that's in place in the options of `request`
        return handleExpiredAccessToken(retry)
      } else {
        return makeRequest()
      }
    }
    // else if the user is making a call against the prop agg service, and their basic auth
    // token is about to expire, we need to renew their basic auth token
    else if (isPropAggRequest(url) && isBasicTokenMissingExpiredOrAboutToExpire()) {
      return handleExpiredBasicToken(retry)
    }
    // tokens are all good, continue making the api request like normal
    else {
      return makeRequest()
    }
  }

  transformRequest(contentType: string, data: any) {
    let transformedData: BodyInit
    switch (contentType) {
      case 'application/json': {
        transformedData = JSON.stringify(data)
        break
      }
      case 'application/x-www-form-urlencoded': {
        const tempData: string[] = []
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            data[key].forEach((value: string) => {
              tempData.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
            })
          } else {
            tempData.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          }
        })
        transformedData = tempData.join('&')
        break
      }
      default: {
        transformedData = data
        break
      }
    }
    return transformedData
  }

  createHeaders(headerOverrides?: OutgoingHttpHeaders | null): OutgoingHttpHeaders {
    let headers = {
      ...this.headers
    } as OutgoingHttpHeaders
    // handle the scenario where the headers were passed in as
    // a function
    Object.keys(this.headers).forEach(key => {
      let header = this.headers[key]
      if (typeof header === 'function') {
        header = header()
      }

      if (header === null) {
        delete headers[key]
      } else {
        headers[key] = header
      }
    })

    // handle any request level header overrides
    if (headerOverrides) {
      headers = {
        ...headers,
        ...headerOverrides
      }
    }

    return headers
  }

  get: <Response = never>(
    url: string,
    headerOverrides?: OutgoingHttpHeaders | null,
    optionsOverrides?: RequestInit
  ) => Promise<Response> = (url, headerOverrides, optionsOverrides) => {
    const {request, baseUrl} = this
    const headers = this.createHeaders(headerOverrides)
    const options = {
      headers,
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      ...optionsOverrides
    } as RequestInit

    // add a cache-busting param for GET requests
    url += (url.indexOf('?') > -1 ? '&_=' : '?_=') + Date.now()

    // This function is passed to request and allows the request to be
    // retried after the new accessToken has been fetched and updated
    const retry = () => {
      return this.get(url, headerOverrides, optionsOverrides)
    }

    return request(`${baseUrl}/${url}`, options, retry)
  }

  head: <Response = any>(
    url: string,
    headerOverrides?: OutgoingHttpHeaders,
    optionsOverrides?: RequestInit
  ) => Promise<Response> = (url, headerOverrides, optionsOverrides) => {
    const {request, baseUrl} = this
    const headers = this.createHeaders(headerOverrides)
    const options = {
      headers,
      method: 'HEAD',
      mode: 'cors',
      ...optionsOverrides
    } as RequestInit

    // add a cache-busting param for GET requests
    url += (url.indexOf('?') > -1 ? '&_=' : '?_=') + Date.now()

    // This function is passed to request and allows the request to be
    // retried after the new accessToken has been fetched and updated
    const retry = () => {
      return this.head(url, headerOverrides, optionsOverrides)
    }

    return request(`${baseUrl}/${url}`, options, retry)
  }

  post: <Response = never>(
    url: string,
    data: any,
    headerOverrides?: OutgoingHttpHeaders | null,
    optionsOverrides?: RequestInit
  ) => Promise<Response> = (url, data, headerOverrides, optionsOverrides) => {
    const {request, baseUrl} = this
    const headers = this.createHeaders(headerOverrides)
    const options = {
      headers,
      body: this.transformRequest(headers['Content-Type'] as string, data),
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      ...optionsOverrides
    } as RequestInit

    // This function is passed to request and allows the request to be
    // retried after the new accessToken has been fetched and updated
    const retry = () => {
      return this.post(url, data, headerOverrides, optionsOverrides)
    }

    return request(`${baseUrl}/${url}`, options, retry)
  }

  put: <Response = never>(
    url: string,
    data: any,
    headerOverrides?: OutgoingHttpHeaders | null,
    optionsOverrides?: RequestInit
  ) => Promise<Response> = (url, data, headerOverrides, optionsOverrides) => {
    const {request, baseUrl} = this
    const headers = this.createHeaders(headerOverrides)
    const options = {
      headers,
      body: this.transformRequest(headers['Content-Type'] as string, data),
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      ...optionsOverrides
    } as RequestInit

    // This function is passed to request and allows the request to be
    // retried after the new accessToken has been fetched and updated
    const retry = () => {
      return this.put(url, data, headerOverrides, optionsOverrides)
    }

    return request(`${baseUrl}/${url}`, options, retry)
  }

  delete: <Response = never>(
    url: string,
    headerOverrides?: OutgoingHttpHeaders | null,
    optionsOverrides?: RequestInit
  ) => Promise<Response> = (url, headerOverrides, optionsOverrides) => {
    const {request, baseUrl} = this
    const headers = this.createHeaders(headerOverrides)
    const options = {
      headers,
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      ...optionsOverrides
    } as RequestInit

    // This function is passed to request and allows the request to be
    // retried after the new accessToken has been fetched and updated
    const retry = () => {
      return this.delete(url, headerOverrides, optionsOverrides)
    }

    return request(`${baseUrl}/${url}`, options, retry)
  }
}
