import React from 'react'
import type {ReactNode} from 'react'
import MUIInputLabel from '@material-ui/core/InputLabel'
import MUIMenuItem from '@material-ui/core/MenuItem'
import MUIFormControl from '@material-ui/core/FormControl'
import MUISelect, {SelectProps} from '@material-ui/core/Select'
import MUIFormHelperText from '@material-ui/core/FormHelperText'

import {getSqaId} from 'services/testingServices'

type Option = {
  disabled?: boolean
  value: string
  label: string
}

type Props = {
  options: Array<Option>
  onChange: SelectProps['onChange']
  value: string | Array<string>
  helperText?: string
  label?: string | JSX.Element
  placeholder?: string
  multiple?: boolean
  disabled?: boolean
  error?: boolean
  className?: string
  renderMenuItemContent?: (options: Option) => ReactNode
  renderValue?: () => ReactNode
  sqaPrefix?: string
}

const Select = ({
  label,
  placeholder,
  options,
  onChange,
  value,
  helperText,
  multiple,
  disabled,
  error,
  className,
  renderMenuItemContent,
  renderValue,
  sqaPrefix
}: Props) => {
  return (
    <MUIFormControl
      fullWidth
      variant='outlined'
      disabled={disabled}
      error={error}
      className={className}
    >
      {label && <MUIInputLabel shrink>{label}</MUIInputLabel>}
      {placeholder && <MUIInputLabel>{placeholder}</MUIInputLabel>}
      <MUISelect
        MenuProps={menuProps}
        multiple={multiple}
        value={value}
        onChange={onChange}
        displayEmpty
        renderValue={renderValue}
        {...getSqaId('select', sqaPrefix)}
      >
        {options.map(option => {
          const {disabled, value, label} = option
          return (
            <MUIMenuItem key={value} value={value} disabled={disabled}>
              {renderMenuItemContent ? renderMenuItemContent(option) : label}
            </MUIMenuItem>
          )
        })}
      </MUISelect>
      {helperText && <MUIFormHelperText>{helperText}</MUIFormHelperText>}
    </MUIFormControl>
  )
}

const menuProps = {
  anchorOrigin: {
    vertical: 45,
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
} as const

export default Select
