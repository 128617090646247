import React from 'react'
import MUITableContainer from '@material-ui/core/TableContainer'
import MUITable from '@material-ui/core/Table'
import MUITableHead from '@material-ui/core/TableHead'
import MUITableRow from '@material-ui/core/TableRow'
import MUITableCell from '@material-ui/core/TableCell'
import MUITableBody from '@material-ui/core/TableBody'
import {getSqaId} from 'services/testingServices'
import {ACCESS_DETAILS_TABLE_HEADER} from 'constants/accessDetailsConstants'
import classnames from 'classnames'
import {Contacts} from 'types/orderTypes'
import {NO_DATA} from 'constants/appConstants'
import {
  INTERIOR_ACCESS_CONTACT_TYPE_DISPLAY_MAP,
  PHONE_NUMBER_FIELDS_MAP
} from 'constants/orderContactConstants'
import {PatternFormat} from 'react-number-format'
import styled from 'styled-components'
import {useParams} from 'react-router-dom'
import useGetOrderData from 'hooks/useGetOrderData'
import {kebabCase} from 'lodash'

export default function AccessDetailsTable() {
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const {data: order} = useGetOrderData(opsOrderId)

  const renderFormattedData = (
    id: typeof ACCESS_DETAILS_TABLE_HEADER[number]['id'],
    contact: Contacts,
    sqaPrefix: string
  ) => {
    let result
    if (id === 'name') {
      result = `${contact.firstName ?? ''} ${contact.lastName}`
    } else if (id === 'phoneNumbers') {
      result = contact?.phoneNumbers
        ?.filter(phoneNumber => phoneNumber.value)
        .map((phoneNumber, index) => (
          <div key={`${phoneNumber}-${index}`}>
            <span>
              {
                <span className='phone-title'>
                  {PHONE_NUMBER_FIELDS_MAP[phoneNumber.type]?.label}
                </span>
              }
              :&nbsp;
              <PatternFormat
                className='text-input'
                format='(###) ###-####'
                type='tel'
                value={phoneNumber.value}
                displayType='text'
                data-sqa-id={`${kebabCase(
                  PHONE_NUMBER_FIELDS_MAP[phoneNumber.type]?.label
                )}-${sqaPrefix}`}
              />
            </span>
          </div>
        ))
    } else if (id === 'type') {
      result = INTERIOR_ACCESS_CONTACT_TYPE_DISPLAY_MAP[contact.type]
    } else if (id === 'email') {
      result = contact.email
    }
    return result
  }

  return (
    <AccessDetailsTable.Styled>
      <MUITable stickyHeader {...getSqaId('-table', 'access-details')}>
        <MUITableHead {...getSqaId('-table-head', 'access-details')}>
          <MUITableRow>
            {ACCESS_DETAILS_TABLE_HEADER.map(
              (
                {
                  id,
                  label,
                  // @ts-ignore there is no sortable in tableHeaders
                  sortable = false,
                  style
                },
                index
              ) => {
                return (
                  <MUITableCell
                    className={classnames('header-sticky', {
                      sortable
                    })}
                    key={id}
                    style={style}
                    data-sqa-id={`header-c${index}`}
                  >
                    {label}
                  </MUITableCell>
                )
              }
            )}
          </MUITableRow>
        </MUITableHead>
        <MUITableBody {...getSqaId('table-body', 'access-details')}>
          {order?.contacts?.map((contact, rowIndex) => (
            <MUITableRow
              key={`${contact.firstName}-row-${rowIndex}`}
              {...getSqaId('row', 'access-detail', rowIndex)}
            >
              {ACCESS_DETAILS_TABLE_HEADER.map((headerCell, colIndex) => {
                const sqaId = `cell-r${rowIndex}c${colIndex}`
                return (
                  <MUITableCell key={`${contact.firstName}-${headerCell.id}`} data-sqa-id={sqaId}>
                    {renderFormattedData(headerCell?.id, contact, sqaId) ?? NO_DATA}
                  </MUITableCell>
                )
              })}
            </MUITableRow>
          ))}
        </MUITableBody>
      </MUITable>
    </AccessDetailsTable.Styled>
  )
}

AccessDetailsTable.Styled = styled(MUITableContainer)`
  margin-top: 24px;
  thead {
    .portal-MuiTableCell-stickyHeader {
      background-color: ${({theme}) => theme.colors.grayscale.white};
    }
    th {
      border-bottom: 1px solid ${({theme}) => theme.colors.stone.darker};
    }
  }
  .phone-title {
    font-weight: 600;
  }
  tbody {
    .portal-MuiTableRow-root {
      vertical-align: top;
    }
  }
`
