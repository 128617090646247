// Style
import styled from 'styled-components'

// Core
import {ButtonProps, MenuProps} from '@material-ui/core'
import {MoreVert} from '@material-ui/icons'
import React, {useState} from 'react'
import {useParams} from 'react-router-dom'

// Components
import Button from 'components/Button'
import {useDownloadDocumentsMap} from 'hooks/useDownloadDocumentsMap'
import DownloadMenu from './DownloadMenu'
import {
  CDADownloadList,
  getAuraDownloadMenuItems,
  getCDADownloadMenuItems
} from 'services/pdfExportServices'
import {downloadOrderDocument} from 'services/ordersServices'
import useGetProductGroup from 'hooks/useGetProductGroup'
import {AURA, BROKER_BASED_VALUATIONS, CDA, INSPECTION} from 'constants/productCardConstants'
import useGetDocumentByOpsOrderId from 'hooks/useGetDocumentByOpsOrderId'

const DropdownButtonExtend = () => {
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl']>(null)
  const handleOpen: ButtonProps['onClick'] = se => setAnchorEl(se.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const {data: documentMap} = useDownloadDocumentsMap()
  const productGroup = useGetProductGroup()
  const shouldDisableDownloadButton = [BROKER_BASED_VALUATIONS, CDA, INSPECTION].includes(
    productGroup
  )
  const {data: documentsData} = useGetDocumentByOpsOrderId()
  const isCDAOrder = productGroup === CDA
  const isAURAOrder = productGroup === AURA

  const getMenuItems = () => {
    let menuItems: {label: string; onClick: () => Promise<void>}[] = []
    if (isCDAOrder) {
      menuItems = getCDADownloadMenuItems(CDADownloadList, opsOrderId, documentMap)
    } else if (isAURAOrder && documentsData) {
      menuItems = getAuraDownloadMenuItems(opsOrderId, documentsData)
    }

    return menuItems
  }

  const menuItems = getMenuItems()

  const onDownloadClickHandler = async () => {
    setLoading(true)

    try {
      await downloadOrderDocument(opsOrderId, {documentType: 'DELIVERABLE_PDF'})
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  return (
    <DropdownButtonExtend.Styled>
      <Button
        title={loading ? 'Downloading' : 'Download Report PDF'}
        size='large'
        variant='secondary'
        disabled={(shouldDisableDownloadButton && !documentMap?.DELIVERABLE_PDF) || loading}
        onClick={onDownloadClickHandler}
      />
      <Button
        className='extend'
        variant='secondary'
        title={<MoreVert className='icon-more-vert' />}
        onClick={handleOpen}
        disabled={!menuItems.length}
      />
      <DownloadMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        items={menuItems}
      />
    </DropdownButtonExtend.Styled>
  )
}
DropdownButtonExtend.Styled = styled.div`
  margin-left: 16px;
  display: inline-block;

  button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .extend {
    padding: 10px 3px;
    min-width: unset;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }

  .icon-more-vert {
    font-size: 1.125rem;
  }
`

export default DropdownButtonExtend
