// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom'
import {preload} from 'swr'
import {useIdleTimer, workerTimers} from 'react-idle-timer'

// Components, services, etc
import {getBasicAuth, refreshTokens, signOut} from 'actions/authActions'
import AppHeader from 'components/AppHeader'
import Footer from 'components/Footer'
import ModalsSystem from 'components/ModalsSystem'
import NotFound from 'components/NotFound'
import ScrollToTop from 'components/ScrollToTop'
import {COOKIE_PREFERENCE_ANALYTICS} from 'constants/appConstants'
import {APP_LOADED, APP_UNMOUNTED} from 'constants/mixpanelConstants'
import {isUserAuthenticatedSelector} from 'selectors/authSelectors'
import {availableRoutesSelector} from 'selectors/routesSelectors'
import {setCookieValue} from 'services/cookieServices'
import {
  handleMixpanelSuperPropertiesAndUser,
  initializeMixpanel,
  trackMixpanelEvent
} from 'services/mixpanel'
import NotificationsSystem from 'components/NotificationsSystem'
import {useAppDispatch} from 'services/store'
import {getProductGroups} from 'services/productsServices'
import {
  USER_IDLE_TIMEOUT,
  USER_IDLE_TIMEOUT_BUFFER,
  USER_IDLE_TIMEOUT_SIGN_OUT,
  USER_IDLE_TIMEOUT_KEEP_WORKING,
  USER_IDLE_TIMER_NAME
} from 'constants/userIdleTimeoutConstants'
import {USER_IDLE_TIMEOUT_MODAL} from 'constants/modalsConstants'
import {openModal, resetModal} from 'actions/modalActions'
import {openNotification} from 'services/notificationServices'
import {LOGGED_OUT_INACTIVITY_MESSAGE} from 'constants/notificationMessageConstants'
import {trackError} from 'services/sentryServices'

// 3rd-party
import CircularProgress from '@material-ui/core/CircularProgress'

const AppMain = () => {
  const dispatch = useAppDispatch()
  const location = useLocation<{error?: string; redirectRoutePath?: string} | undefined>()
  const history = useHistory()
  const availableRoutes = useSelector(availableRoutesSelector)
  const isLoggedIn = useSelector(isUserAuthenticatedSelector)
  const [isLoading, setIsLoading] = useState(true)
  const isRedirect = location.pathname.includes('redirect')
  const redirectRoutePath = location?.state?.redirectRoutePath

  const {activate, getRemainingTime, message} = useIdleTimer({
    name: USER_IDLE_TIMER_NAME,
    timeout: USER_IDLE_TIMEOUT,
    promptBeforeIdle: USER_IDLE_TIMEOUT_BUFFER,
    eventsThrottle: 1000, // throttle the events handler so we don't restart the timer on every keypress and thus save CPU resources
    stopOnIdle: true,
    timers: workerTimers,
    crossTab: true,
    syncTimers: 200,
    disabled: !isLoggedIn,
    onIdle: () => {
      dispatch(resetModal())
      openNotification({
        type: 'error',
        text: LOGGED_OUT_INACTIVITY_MESSAGE
      })
      dispatch(signOut())
    },
    onPrompt: () =>
      dispatch(
        openModal({
          modalType: USER_IDLE_TIMEOUT_MODAL,
          getRemainingTime,
          handleKeepWorking,
          handleSignOut
        })
      ),
    onMessage: message => {
      if (message === USER_IDLE_TIMEOUT_SIGN_OUT) {
        handleUserIdleTimeoutSignOut()
      } else if (message === USER_IDLE_TIMEOUT_KEEP_WORKING) {
        handleUserIdleTimeoutKeepWorking()
        dispatch(resetModal())
      } else if (message === 'NEW_TIMER_INITIALIZED') {
        dispatch(resetModal())
      }
    },
    events: [
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove'
    ]
  })

  useEffect(() => {
    if (isLoggedIn) {
      message('NEW_TIMER_INITIALIZED')
    }
  }, [isLoggedIn, message])

  useEffect(() => {
    if (isLoggedIn) {
      preload('/products', getProductGroups).catch(() => {})
    }
  }, [isLoggedIn])

  useEffect(() => {
    const getAuth = async () => {
      setIsLoading(true)
      const refreshDispatch = dispatch(refreshTokens()).catch(() => {})

      Promise.all([refreshDispatch, dispatch(getBasicAuth())]).then(resArray => {
        setUpMixpanel()
        setIsLoading(false)
      })
    }
    getAuth()

    const sessionEndHandler = () => {
      trackMixpanelEvent(APP_UNMOUNTED)
    }
    // Send app unmounted Mixpanel event before window is closed
    window.addEventListener('beforeunload', sessionEndHandler)
    return () => {
      window.removeEventListener('beforeunload', sessionEndHandler)
    }
  }, [dispatch])

  // Set cookie preference if doesnt exist
  useEffect(() => {
    const analyticsCookie = document.cookie
      ?.split('; ')
      ?.find(row => row.startsWith(COOKIE_PREFERENCE_ANALYTICS))
    if (!analyticsCookie) {
      setCookieValue(COOKIE_PREFERENCE_ANALYTICS, 'true; path=/')
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn && redirectRoutePath) {
      history.push(redirectRoutePath)
    }
  }, [history, isLoggedIn, redirectRoutePath])

  const setUpMixpanel = () => {
    // initialize Mixpanel
    initializeMixpanel()

    // track the app loaded event in Mixpanel
    trackMixpanelEvent(APP_LOADED)
  }

  // We track the super properties based on the user's logged in status. We only
  // want to run this after the app loads. It will also fire again when the user's
  // logged in state changes.
  // IMPORTANT - be sure to keep this effect below the one that sets up Mixpanel
  // by calling initializeMixpanel
  useEffect(() => {
    if (!isLoading) {
      handleMixpanelSuperPropertiesAndUser()
    }
  }, [isLoading, isLoggedIn])

  const handleUserIdleTimeoutKeepWorking = () => {
    dispatch(refreshTokens()).catch(err => {
      trackError(err)
    })
  }

  const handleUserIdleTimeoutSignOut = () => {
    dispatch(signOut())
  }

  const handleKeepWorking = () => {
    message(USER_IDLE_TIMEOUT_KEEP_WORKING)
    activate()
    handleUserIdleTimeoutKeepWorking()
  }

  const handleSignOut = () => {
    message(USER_IDLE_TIMEOUT_SIGN_OUT)
    handleUserIdleTimeoutSignOut()
  }

  return (
    <AppMain.Styled className='app-main'>
      {isLoading ? (
        <div className='loading-wrap'>
          {/* inner div needed for IE11 */}
          <div>
            <CircularProgress size={100} />
          </div>
        </div>
      ) : (
        <Fragment>
          <ScrollToTop />
          <AppHeader />
          <Switch>
            {location?.state?.error ? (
              <NotFound />
            ) : (
              availableRoutes.map(({label, path, Component}) => (
                <Route key={label} exact={!path.includes(':')} path={path} component={Component} />
              ))
            )}
            <Redirect
              exact
              from='/'
              to={{
                pathname: '/products',
                state: {
                  redirectRoutePath: isRedirect ? `${location.pathname}${location.search}` : null
                }
              }}
            />
            <Route path='*'>
              <NotFound />
            </Route>
          </Switch>
          <Footer />
          <ModalsSystem />
          <NotificationsSystem position='bottom-right' />
        </Fragment>
      )}
    </AppMain.Styled>
  )
}

AppMain.Styled = styled.div<{headerHeight?: number}>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: ${({headerHeight}) => headerHeight ?? 0}px;
  overflow-x: hidden;
  padding-top: 60px;

  .loading-wrap {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-top: ${({headerHeight = 0}) => -headerHeight ?? 0}px;

    /* inner div needed for IE11 */
    > div {
      display: flex;
      width: 48px;
    }
  }
`

export default AppMain
