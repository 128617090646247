import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import {Field} from 'react-final-form'

import DetailsSection from 'components/OrderForm/DetailsSection'
import {ProductDetailsInputsType, ProductGroupType, ProductOptions} from 'types/productTypes'
import Select from 'components/Select'
import TextInput from 'components/TextInput'
import useSupportedProductGroups from 'hooks/useSupportedProductGroups'
import {getInputValidity} from 'services/formValidationHelpers'
import {requiredValidationRules} from 'constants/validationRulesConstants'
import {getProductOptions} from 'services/productsServices'

export interface ProductDetailsProps {
  productGroup: ProductGroupType
  inputs: ProductDetailsInputsType
}

const ProductDetails = ({productGroup, inputs}: ProductDetailsProps) => {
  const {
    productCode: shouldShowProductCode,
    trackingId: shouldShowTrackingId,
    orderInstructions: shouldShowOrderInstructions
  } = inputs
  const supportedProductGroups = useSupportedProductGroups()

  let productOptions: ProductOptions = []
  if (shouldShowProductCode) {
    productOptions = getProductOptions(productGroup, supportedProductGroups)
  }

  const shouldShowFirstRow = shouldShowProductCode || shouldShowTrackingId

  const shouldDisableProductType = productOptions.length === 1

  return (
    <ProductDetails.Styled>
      <DetailsSection title='Product Details' className='product-details-section'>
        <div className='row-container'>
          {shouldShowFirstRow && (
            <div className='row'>
              {shouldShowProductCode && (
                <div className='field'>
                  <label htmlFor='productCode' className='label'>
                    Product
                  </label>
                  <div id='productCode' className='input-wrapper'>
                    <Field
                      name='productType'
                      validate={value => getInputValidity(value, requiredValidationRules).errorMsg}
                    >
                      {({input, meta}) => {
                        return (
                          <Select
                            className={classnames('product-type-input', {
                              'product-type-input-disabled': shouldDisableProductType
                            })}
                            options={productOptions}
                            value={input.value}
                            error={meta.error && meta.submitFailed}
                            helperText={meta.submitFailed && meta.error}
                            onChange={input.onChange}
                            sqaPrefix='product-type'
                            disabled={shouldDisableProductType}
                          />
                        )
                      }}
                    </Field>
                  </div>
                </div>
              )}
              {shouldShowTrackingId && (
                <div className='field'>
                  <label htmlFor='trackingId' className='label'>
                    Tracking ID <span className='label-optional'> - Optional</span>
                  </label>
                  <div id='trackingId' className='input-wrapper'>
                    <Field name='clientTrackingId'>
                      {({input}) => (
                        <TextInput
                          className='tracking-id-input'
                          value={input.value}
                          onChange={input.onChange}
                          sqaPrefix='tracking-id'
                        />
                      )}
                    </Field>
                  </div>
                </div>
              )}
            </div>
          )}
          {shouldShowOrderInstructions && (
            <div className='row-order-instructions'>
              <div className='field'>
                <label htmlFor='orderInstructions' className='label'>
                  Order Instructions <span className='label-optional'> - Optional</span>
                </label>
                <div id='orderInstructions'>
                  <Field name='customerNotes'>
                    {({input}) => {
                      return (
                        <TextInput
                          className='text-input'
                          value={input.value}
                          multiline
                          onChange={input.onChange}
                          sqaPrefix='order-instructions'
                        />
                      )
                    }}
                  </Field>
                </div>
              </div>
            </div>
          )}
        </div>
      </DetailsSection>
    </ProductDetails.Styled>
  )
}

ProductDetails.Styled = styled.div`
  .row-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .field {
        max-width: 211px;
      }

      .product-type-input {
        &-disabled {
          border: 1px solid ${({theme}) => theme.colors.stone.light};
          border-radius: 6px;
          padding-bottom: 0;
        }
      }
    }

    .row,
    .row-order-instructions {
      .field {
        min-width: 211px;
        display: flex;
        flex-direction: column;
      }

      .label {
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin-bottom: 4px;

        &-optional {
          font-size: 0.75rem;
          font-weight: 300;
        }
      }
    }

    .row-order-instructions {
      .field {
        max-width: 438px;
      }
    }
  }

  .portal-MuiOutlinedInput-multiline {
    padding: 12px;
  }
`

export default ProductDetails
