import type {OrderFormConfigByProductGroupType} from 'types/productTypes'
import {productGroupDictionary} from 'constants/productCardConstants'

// TODO: remove this AURA code once AURA code is deployed
// @ts-ignore
export const ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP: OrderFormConfigByProductGroupType = {
  CLEARPROP: {
    productGroup: 'CLEARPROP',
    image: productGroupDictionary.CLEARPROP.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: false
        }
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  },
  CDA: {
    productGroup: 'CDA',
    image: productGroupDictionary.CDA.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: true
        }
      },
      LOAN_DETAILS: {
        inputs: {
          loanNumber: true,
          loanType: false,
          loanPurpose: true,
          borrower: true,
          estimatedClosingDate: false,
          caseNumber: false,
          duCaseNumber: false,
          lpaCaseNumber: false,
          primaryGse: false,
          lenderOrClientName: true,
          investor: true
        }
      },
      UPLOAD_DOCUMENTS: {
        requiredDocuments: ['APPRAISAL']
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  },
  AVM: {
    productGroup: 'AVM',
    image: productGroupDictionary.AVM.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: false
        }
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  },
  BROKER_BASED_VALUATIONS: {
    productGroup: 'BROKER_BASED_VALUATIONS',
    image: productGroupDictionary.BROKER_BASED_VALUATIONS.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: true
        }
      },
      LOAN_DETAILS: {
        inputs: {
          loanNumber: true,
          loanType: false,
          loanPurpose: false,
          borrower: true,
          estimatedClosingDate: false,
          caseNumber: false,
          duCaseNumber: false,
          lpaCaseNumber: false,
          primaryGse: false,
          lenderOrClientName: false,
          investor: false
        }
      },
      CONTACTS_DETAILS: {
        inputs: {
          email: true,
          phoneNumbers: true
        }
      },
      UPLOAD_DOCUMENTS: {
        requiredDocuments: []
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  },
  INSPECTION: {
    productGroup: 'INSPECTION',
    image: productGroupDictionary.INSPECTION.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: true
        }
      },
      LOAN_DETAILS: {
        inputs: {
          loanNumber: true,
          loanType: false,
          loanPurpose: false,
          borrower: true,
          estimatedClosingDate: false,
          caseNumber: false,
          duCaseNumber: false,
          lpaCaseNumber: false,
          primaryGse: false,
          lenderOrClientName: false,
          investor: false
        }
      },
      CONTACTS_DETAILS: {
        inputs: {
          email: true,
          phoneNumbers: true
        }
      },
      UPLOAD_DOCUMENTS: {
        requiredDocuments: []
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  },
  AURA: {
    productGroup: 'AURA',
    image: productGroupDictionary.AURA.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: false
        }
      },
      UPLOAD_DOCUMENTS: {
        requiredDocuments: []
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  }
}

export const ORDER_FORM_SQA_PREFIX = 'order-form'
export const STRIPE_3D_SECURE_ERROR = 'Stripe3dSecureError'

export const MAXIMUM_PRODUCTS_GROUPS_REQUEST_RETRY_FAILED = 5

export type ContactsDetailsFieldName = 'email' | 'phoneNumbers'
export type ContactsDetailsInputsType = {[key in ContactsDetailsFieldName]: boolean}
