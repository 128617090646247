import {createSelector} from 'reselect'
import {NO_DATA} from 'constants/appConstants'
import {LOADING} from 'constants/reduxStatuses'
import moment from 'moment'
import type {RootState} from 'services/store'
import type {OpsOrderType} from 'types/orderTypes'
import type {ProductGroupType, ProductType} from 'types/productTypes'

export const rootOrdersByAddressSelector = (state: RootState) => {
  return state.ordersByAddress
}
export const ordersByAddressDataSelector = createSelector(
  [rootOrdersByAddressSelector],
  ordersByAddress => ordersByAddress.data
)
export const ordersByAddressSelector = createSelector(
  [ordersByAddressDataSelector],
  ordersByAddressData => {
    return ordersByAddressData.Items
  }
)
export const ordersByAddressByProductTypeDictionarySelector = createSelector(
  [ordersByAddressSelector],
  ordersByAddress =>
    ordersByAddress.reduce((accumulator, order) => {
      const {productType} = order
      if (!accumulator[productType]) {
        accumulator[productType] = []
      }
      accumulator[productType].push(order)
      return accumulator
    }, {} as {[key in ProductType]: OpsOrderType[]})
)

// By opsOrderId
export const rootOrderByOpsOrderIdSelector = (state: RootState) => state.orderByOpsOrderId
export const orderByOpsOrderIdStatusSelector = createSelector(
  [rootOrderByOpsOrderIdSelector],
  orderByOpsOrderId => orderByOpsOrderId.status
)

const orderByOpsOrderIdDataSelector = createSelector(
  [rootOrderByOpsOrderIdSelector],
  orderByOpsOrderId => orderByOpsOrderId.data
)
export const orderByOpsOrderIdSelector = createSelector(
  [orderByOpsOrderIdDataSelector],
  orderByOpsOrderIdData => orderByOpsOrderIdData || {}
)
export const orderByOpsOrderIdErrorSelector = createSelector(
  [rootOrderByOpsOrderIdSelector],
  orderByOpsOrderId => orderByOpsOrderId.error
)

// Orders (for orders table)
export const rootOrdersSelector = (state: RootState) => state.orders
export const ordersStatusSelector = createSelector([rootOrdersSelector], orders => orders.status)
export const ordersDataSelector = createSelector([rootOrdersSelector], orders => orders.data)
export const ordersErrorSelector = createSelector([rootOrdersSelector], orders => orders.error)
export const ordersItemsSelector = createSelector([ordersDataSelector], data => data.Items)
export const isOrdersDataLoadingSelector = createSelector(
  [ordersStatusSelector],
  OrdersStatus => OrdersStatus === LOADING
)

export const ordersPerPageSelector = createSelector(
  [ordersDataSelector],
  data => data.ordersPerPage
)

export const lastEvaluatedSelector = createSelector(
  [ordersDataSelector],
  data => data.LastEvaluatedKey
)

export type OrdersTableDataItem = {
  clientTrackingId: string
  created: string
  opsOrderId: string
  pid: string
  productType: ProductType
  suppliedCityStateZip: string
  photoUrl: string
  suppliedStreet: string
  suppliedAddress: Required<OpsOrderType>['suppliedAddress']
  status: Required<OpsOrderType>['status']
  paymentFailed?: boolean
  productGroup: ProductGroupType

  orderedBy?: {firstName?: string; lastName?: string}
  productOrderId: string
  paymentFailedReason?: string
}

export const ordersTableDataSelector = createSelector([ordersItemsSelector], data => {
  if (data) {
    const dataKeys = [
      'clientTrackingId',
      'created',
      'opsOrderId',
      'pid',
      'productOrderId',
      'productType',
      'suppliedCityStateZip',
      'photoUrl',
      'suppliedStreet',
      'suppliedAddress',
      'status',
      'paymentFailed',
      'paymentFailedReason',
      'orderedBy'
    ] as const

    const formatData = (key: typeof dataKeys[number], value: any) => {
      let returnValue: any = NO_DATA
      switch (key) {
        case 'created':
          if (value) {
            returnValue = moment(value).format('MMM DD, YYYY')
          }
          break
        default:
          returnValue = value
      }
      return returnValue
    }

    const returnData: OrdersTableDataItem[] = []
    data.forEach(Item => {
      const returnItem = {} as any
      dataKeys.forEach(key => {
        // @ts-ignore  suppliedCityStateZip is not exists in OpsOrderType
        returnItem[key] = formatData(key, Item[key])
      })
      returnData.push(returnItem)
    })

    return returnData
  }
  return []
})

export const opsOrderIdToProductOrderIdMapSelector = createSelector(
  [ordersByAddressSelector, ordersItemsSelector, orderByOpsOrderIdSelector],
  (ordersByAddress, ordersTableOrders, orderByOpsOrderIdSelector) => {
    return [...ordersByAddress, ...ordersTableOrders, orderByOpsOrderIdSelector].reduce(
      (ordersMap, currentOrder) => {
        const {opsOrderId, productOrderId} = currentOrder
        if (opsOrderId) ordersMap[opsOrderId] = productOrderId
        return ordersMap
      },
      {} as {[key: string]: string | undefined}
    )
  }
)

// Pagination Selectors
export const rootOrdersHistorySelector = (state: RootState) => state.ordersHistory
export const orderPageHistorySelector = createSelector(
  [rootOrdersHistorySelector],
  ({history}) => history
)
export const currentPageSelector = createSelector(
  [rootOrdersHistorySelector],
  ({currentPage}) => currentPage
)
export const pageHistorySelector = createSelector(
  [orderPageHistorySelector, currentPageSelector],
  (history, page) => history[page]
)
