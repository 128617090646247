import {CircularProgress} from '@material-ui/core'
import AccessDetailsTable from 'components/AccessDetailsTable'
import Card from 'components/Card'
import useGetOrderData from 'hooks/useGetOrderData'
import React, {Fragment} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'

export default function AccessDetails() {
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const {data: order, isLoading} = useGetOrderData(opsOrderId)
  const lockboxCode = order?.lockbox
  return (
    <AccessDetails.Styled className={isLoading ? 'loading' : ''}>
      {isLoading ? (
        <div className='spinner'>
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          {lockboxCode && (
            <p className='lockbox'>
              <span className='lockbox-title'>Lockbox Code:</span>
              {lockboxCode}
            </p>
          )}
          <AccessDetailsTable />
        </Fragment>
      )}
    </AccessDetails.Styled>
  )
}

AccessDetails.Styled = styled(Card)`
  min-height: 341px;
  max-height: 375px;
  overflow-y: auto;
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    .portal-MuiCircularProgress-svg {
      height: 48px;
      width: 48px;
    }
  }
  .lockbox {
    background-color: ${({theme}) => theme.colors.stone.lighter};
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px #00000014;
    margin-bottom: 24px;
    padding: 8px;
    &-title {
      font-weight: 600;
      margin-right: 4px;
    }
  }
`
