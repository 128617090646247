import {createQueryStringFromObject} from 'services/urlServices'
import {
  Contacts,
  CreateOrderRequestBody,
  CreateOrderResponse,
  DocumentId,
  DocumentType,
  DocumentUrlResponse,
  OpsOrderId,
  OpsOrderType,
  OrderAlert,
  OrderDisplayStatus,
  OrdersForAddressArguments,
  OrderStatus
} from 'types/orderTypes'
import {ORDER_DISPLAY_ALERTS_MAP, ORDER_DISPLAY_STATUS_MAP} from 'constants/ordersConstants'
import {OrdersTableDataItem} from 'selectors'
import {opsApi} from './apis'
import {downloadPdf} from './pdfExportServices'
import {openNotification} from './notificationServices'
import {DOWNLOAD_FAILED_MESSAGE} from 'constants/notificationMessageConstants'
import {AURA, BROKER_BASED_VALUATIONS, CDA, INSPECTION} from 'constants/productCardConstants'
import {ProductGroupType} from 'types/productTypes'

export const createGetUrlWithCustomerKey = (getUrl: string, customerKey?: string) => {
  // we conditionally add the user's orgKey to the query params if needed
  if (customerKey) {
    let joinCharacter = '&'
    if (!getUrl.match(/\?([^&]+)=/)) {
      joinCharacter = '?'
    }
    getUrl = `${getUrl}${joinCharacter}${createQueryStringFromObject({
      orgKey: customerKey
    })}`
  }

  return getUrl
}

export const getAlertsByOrder = (order: OrdersTableDataItem): OrderAlert[] => {
  const alerts: OrderAlert[] = []
  const {status, paymentFailed, productOrderId} = order
  const {orderStatus, supportNeeded, hold, cancel, revision} = status

  if (hold?.isOnHold) {
    alerts.push('On Hold')
  }

  if (cancel?.cancelRequested) {
    alerts.push('Cancelation Requested')
  }

  if (revision?.revisionRequested) {
    alerts.push('Revision Requested')
  }

  if (orderStatus === 'ORDERED' && supportNeeded) {
    alerts.push('Contact Support')
  } else if (orderStatus === 'ORDERED' && paymentFailed) {
    alerts.push('Payment Failed')
  } else if (orderStatus === 'ORDERED' && !productOrderId) {
    alerts.push('Payment Processing')
  }

  return alerts
}

export const getOrderAlertTooltipTextByAlertType = (
  alertType: OrderAlert,
  order: OrdersTableDataItem
): string => {
  let text
  if (alertType === 'Contact Support' && order.opsOrderId) {
    text = `${ORDER_DISPLAY_ALERTS_MAP[alertType].text}${order.opsOrderId.slice(-5)}`
  } else {
    text = ORDER_DISPLAY_ALERTS_MAP[alertType].text ?? ''
  }

  return text
}

export const getOrderProgressStepByOrderStatus = (
  orderStatus: Exclude<OrderStatus, 'CANCELED'> | undefined
): number => {
  let step
  if (orderStatus === 'ACCEPTED') {
    step = 0
  } else if (orderStatus === 'ASSIGNED') {
    step = 1
  } else if (orderStatus === 'REVIEWING') {
    step = 2
  } else if (orderStatus === 'COMPLETED') {
    step = 3
  } else {
    step = -1
  }
  return step
}

export const getAssignedOrderDisplayStatus = (
  productGroup: ProductGroupType
): OrderDisplayStatus => {
  let outputStatus: OrderDisplayStatus
  if (productGroup === CDA) {
    outputStatus = 'Appraiser Working'
  } else if (productGroup === BROKER_BASED_VALUATIONS || productGroup === INSPECTION) {
    outputStatus = 'Broker Working'
  }

  return outputStatus
}

export const getOrderStatus = (
  order: OpsOrderType,
  productGroup: ProductGroupType
): OrderDisplayStatus => {
  const orderStatus = order?.status?.orderStatus
  let outputStatus: OrderDisplayStatus
  if (orderStatus) {
    outputStatus = ORDER_DISPLAY_STATUS_MAP[orderStatus]
    if (order?.status?.orderStatus === 'ASSIGNED') {
      if (productGroup === CDA) {
        outputStatus = 'Appraiser Working'
      } else if (productGroup === BROKER_BASED_VALUATIONS || productGroup === INSPECTION) {
        outputStatus = 'Broker Working'
      }
    }
  }
  return outputStatus
}

export const getOrderProgressStepNames = (productGroup: ProductGroupType): OrderDisplayStatus[] => {
  let orderProgressStepNames
  if (productGroup === AURA) {
    orderProgressStepNames = [ORDER_DISPLAY_STATUS_MAP.ACCEPTED, ORDER_DISPLAY_STATUS_MAP.COMPLETED]
  } else {
    const assignedOrderDisplayStatus = getAssignedOrderDisplayStatus(productGroup)
    orderProgressStepNames = [
      ORDER_DISPLAY_STATUS_MAP.ACCEPTED,
      assignedOrderDisplayStatus,
      ORDER_DISPLAY_STATUS_MAP.REVIEWING,
      ORDER_DISPLAY_STATUS_MAP.COMPLETED
    ]
  }

  return orderProgressStepNames
}

export const createOrder = (payload: CreateOrderRequestBody) => {
  return opsApi.post<CreateOrderResponse>('orders', payload)
}

export const createContact = (
  opsOrderId: Required<OpsOrderId>,
  payload: {contacts: Contacts[]}
) => {
  return opsApi.post<Contacts>(`orders/${opsOrderId}/contacts`, payload)
}

export const downloadOrderDocument = async (
  opsOrderId: string,
  document: {documentType: DocumentType} | {documentId: DocumentId}
) => {
  let documentIdentifier
  if ('documentType' in document) {
    documentIdentifier = document.documentType
  } else {
    documentIdentifier = document.documentId
  }
  try {
    const {url, fileName} = await opsApi.get<DocumentUrlResponse>(
      `orders/${opsOrderId}/documents/${documentIdentifier}/url`
    )
    const response = await fetch(url)
    const blob = await response.blob()
    downloadPdf(fileName, blob)
  } catch (error) {
    console.error(error)
    openNotification({
      type: 'error',
      text: DOWNLOAD_FAILED_MESSAGE
    })
  }
}

export const getOrdersForAddressUrl = (
  orderArgs: OrdersForAddressArguments,
  customerKey?: string
): string => {
  let baseUrl = ''

  if ('PID' in orderArgs) {
    const PID = orderArgs.PID
    baseUrl = `orders?index=PID&is=${PID}`
  } else if ('addressKey' in orderArgs) {
    const addressKey = orderArgs.addressKey
    baseUrl = `orders?index=addressKey&is=${encodeURIComponent(addressKey)}`
  }

  return customerKey ? `${baseUrl}&orgKey=${customerKey}` : baseUrl
}
